/*
Cambios:
    enableMock: true, //RCLL Mar2023
    apiPrefix: '/api', //RCLL Mar2023
*/
const appConfig = {
    apiPrefix: process.env.NODE_ENV==='development' ? 'http://192.168.1.28:3010' : 'https://api.business.primesoft.com.pe',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'es',
    enableMock: false, 
}

export default appConfig
/*
En Demo esta asi

const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/app/sales/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: true,
}

export default appConfig
*/